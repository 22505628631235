import {useState, useEffect} from 'react'
import SeleFecha from './selector_fecha.js'
import { FaAngleDown, FaAngleUp }  from "react-icons/fa"
export default function Caja (props) {
    const [date_d, setDate_d] = useState(new Date())
    const [date_h, setDate_h] = useState(new Date())
    const [recibos, setRecibos] = useState([])
    const [recibosNo, setRecibosNo] = useState([])
    const [elegido, setElegido] = useState(0)
    const [efe, setEfe] = useState(0)
    const [tra, setTra] = useState(0)

    const pasado_d = (date_d) => new Date() >= date_d
    const pasado_h = (date_h) => new Date() >= date_h
    var total_tra
    var total_efe
    const fecha = ((f) => {
        var d = new Date(f)
        var anio = d.getFullYear()
        var mes = d.getMonth() + 1
        mes = (mes<10) ? '0' + mes : mes
        var dia = d.getDate()
        dia = (dia<10) ? '0' + dia : dia 
        return anio + '-' + mes + '-' + dia
      })

    useEffect (() => {
        fetch(props.back + 'admin/traer_recibos/fechad/' + fecha(date_d) + '/fechah/' + fecha(date_h) ,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())
        .then (json => {
            setRecibos(json)
            total_tra=0
            total_efe=0
            json.map(a => {
                total_tra += parseInt(a.transferencia)
                total_efe += parseInt(a.efectivo)
            })
            setTra(total_tra)
            fetch(props.back + 'admin/traer_recibos_ing/fechad/' + fecha(date_d) + '/fechah/' + fecha(date_h) ,{
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + props.token,
                    'Content-Type': 'application/json' 
                }
            })
            .then (response => response.json())
            .then (json => {
                setRecibosNo(json)
                json.map(a => {total_efe += parseInt(a.importe)})
                setEfe(total_efe)
            })
        })
    }, [date_d, date_h])

    return (<>
        <div className="p-2 justify-center w-full font-semibold flex flex-row border-b-2 border-gray-600">
            <SeleFecha c={'Desde'} fecha={date_d} setFecha={setDate_d} pasado={pasado_d}/>
            <SeleFecha c={'Hasta'} fecha={date_h} setFecha={setDate_h} pasado={pasado_h}/>
        </div>
        <div className="flex flex-row h-3/5 border-b-2 border-gray-600 overflow-y-auto">
            <div className="flec flex-col w-2/3 border-r-2 border-gray-600">
                <div className="flex flex-col items-center">
                    <label className="text-lg">Cuotas Socios</label>
                </div>
                <div className="p-2 grid grid-cols-7 justify-start">
                    <label className="px-2 text-center col-span-2">Apellido</label>
                    <label className="px-2 text-center">Nombre</label>
                    <label className="px-2 text-center">Transferencia</label>
                    <label className="px-2 text-center">Efectivo</label>
                    <label className="px-2 text-center">Total</label>
                    <label className="px-2 text-center">Fecha</label>
                </div>
                {recibos.map (a => {
                return (<>
                    <div className="flex flex-row w-full">
                        <div>
                        {a.id !== elegido ?
                            <FaAngleDown 
                                className="text-lg"
                                onClick={() => setElegido(a.id)}
                            />
                        :
                            <FaAngleUp  
                                className="text-lg"
                                onClick={() => setElegido(0)}
                            />
                        }
                        </div>
                        <div className="px-2 grid grid-cols-7 w-full" key={a.id}>
                            <label className="px-2 col-span-2">{a.socio.apellido}</label>
                            <label className="px-2">{a.socio.nombre}</label>
                            <label className="px-2 text-right">{parseInt(a.transferencia)}</label>
                            <label className="px-2 text-right">{parseInt(a.efectivo)}</label>
                            <label className="px-2 text-right">{parseInt(a.transferencia) + parseInt(a.efectivo)}</label>
                            <label className="px-2 text-center">{a.cuando.substring(0, a.cuando.search('T'))}</label>      
                        </div>
                    </div>
                {elegido === a.id ? 
                    <div className="flex justify-center">
                        <div className="border-2 border-gray-600 mx-5 w-1/2">
                        {a.recibo_deta.map( z => {
                            return (<>
                            <div className="grid grid-cols-4 px-2">
                                {console.log(z)}
                                <label className="text-center">{z.concepto}</label>
                                <label className="text-center">{z.concepto !== 'socio' ? z.actividad : z.categoria}</label>
                                <label className="text-right">{z.importe}</label>
                                <label className="text-right">{z.cuota}</label>
                            </div>
                            </>)
                        }) 
                    } 
                    </div>
                    </div>
                : ''}
                </>)
            })}
            </div>
            <div className="w-1/3 text-center">
                <label className="text-lg">Ingresos No Socios</label>
                <div className="p-2 grid grid-cols-3 text-start">
                    <label className="px-2 text-center">Documento</label>
                    <label className="px-2 text-center">Motivo</label>
                    <label className="px-2 text-center">Importe</label>
                </div>
                <div className="px-2">
                {recibosNo.map (a => {
                    return (<>
                        <div className="px-2 grid grid-cols-3">
                            <label>{a.documento}</label>
                            <label>{a.motivo}</label>
                            <label className="px-2 text-right">{parseInt(a.importe)}</label>
                        </div>
                    </>)
                })}
                </div>
            </div>
        </div>
        <div className="grid grid-cols-3 w-full px-2">
            <div className="grid grid-cols-2 px-2">
                <label className="text-lg font-semibold mr-5">Total Transferencias</label>
                <label className="text-lg font-semibold">{tra}</label>
            </div>
            <div className="grid grid-cols-2 px-2">
                <label className="text-lg font-semibold mr-5">Total Efectivo</label>
                <label className="text-lg font-semibold">{efe}</label>
            </div>
            <div className="grid grid-cols-2 px-2">
                <label className="text-lg font-semibold mr-5">Total</label>
                <label className="text-lg font-semibold">{tra + efe}</label>
            </div>
        </div>
    </>)
}