
export default function Responsable(props) {
    const formato_text = 'w-32 self-justify-end px-2'
    const data1 = [
        {cartel: 'apellido', dato: 'apellido_ref'},
        {cartel: 'nombre', dato: 'nombre_ref'}
    ]
    const data2 = [
        {cartel: 'documento', dato: 'documento_ref'},
        {cartel: 'teléfono', dato: 'telefono_ref'}
    ]
    
    return (<>
    <div className="flex flex-col h-full px-2">
        <label className="text-lg font-semibold mb-2">Datos Autoirzado / Responsable</label>
        <div className="flex flex-col md:flex-row justify-between">
            <div>
        {data1.map (a => {
            return (<>
                <div className="flex flex-row py-2 justify-between">
                <label className="capitalize">{a.cartel}</label>
                <input 
                    className={formato_text}
                    type="text"
                    value={props.socio[a.dato]}
                    onChange={e => props.setSocio({...props.socio, [a.dato]: e.target.value})}
                />
                </div>
             </>)
        })
         }</div>
         <div>
        {data2.map (a => {
            return (<>
                <div className="flex flex-row py-2 justify-between">
                <label className="capitalize">{a.cartel}</label>
                <input 
                    className={formato_text}
                    type="text"
                    value={props.socio[a.dato]}
                    onChange={e => props.setSocio({...props.socio, [a.dato]: e.target.value})}
                />
                </div>
             </>)
        })
      }</div>
    </div>
    </div>
    </>)
}