export default function ControlesSocio (props) {

    function guardar () {
        if (props.file !== null) {
            var data = new FormData()
            data.append('img', props.file)
            var user = {
                body: data,
                method: 'POST'
            }
            fetch(props.back + 'subir_imagen',user)
            .then (response => response.json())
            .then (json => guardar_resto(json.filename))
        } else
            guardar_resto('')
    }

    function guardar_resto (ima) {
        var a = props.socio
        a = {...a, nacimiento: new Date(a.nacimiento), alta_sistema: new Date(a.alta_sistema)}
        if(ima !== '') 
            a = {...a, imagen: ima} 
        fetch(props.back + 'admin/update', {
            body: JSON.stringify(a),
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())    
        .then (json => props.setSocio({id: 0}))
    }
    
    return (<>
    <div className="flex flex-row justify-center">
        <button
            className="text-sm md:text-lg my-2 p-2 mx-2 md:mx-10 rounded-xl bg-green-200 hover:bg-green-800 hover:text-white"
            onClick={e => guardar()}
        >
            Guardar Cambios
        </button>
        <button
            className="text-sm md:text-lg my-2 p-2 mx-2 md:mx-10 rounded-xl bg-red-200 hover:bg-red-800 hover:text-white"
            onClick={e => props.setSocio({id:0})}
        >
             Descartar Cambios
        </button>
    </div>
    </>)
}