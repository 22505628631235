import {useState, useEffect} from 'react'
import dayjs from 'dayjs'
import * as XLSX from 'xlsx/xlsx.mjs'

export default function LisCateg (props) {
    const [seleccionados, setSeleccionados] = useState(0)
    const [data, setdata] = useState('')
    const [resultado, setResultado] = useState([])
    const [socios, setSocios] = useState([])
    const [elegida, setElegida] = useState('Futsal')
    const [actiMul, setActiMul] = useState([])
    const [importes, setImportes] = useState([])
    
    var cont=0

    function guardar() {
        const worksheet = XLSX.utils.json_to_sheet(resultado);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const fecha = new Date()
        const a = fecha.getFullYear().toString()
        const b = (fecha.getMonth() + 1).toString().padStart(2,'0')
        const c = fecha.getDate().toString().padStart(2,'0')
        const d = a + '-' + b +  '-' + c
        const hora = fecha.getHours().toString().padStart(2,'0')
        const minutos = fecha.getMinutes().toString().padStart(2,'0')
        const segundos = fecha.getSeconds().toString().padStart(2,'0')
        const timer = hora + '-' + minutos + '-' + segundos
        XLSX.writeFile(workbook, 'listados_socios_' + d + ' ' + timer + '.xlsx')
    }

    useEffect (() => {
        function traer_socios() {
            if(props.token!=='') {
                fetch(props.back + 'admin/traer_socios', {
                    method: 'GET',
                    headers: {
                      'Authorization': 'Bearer ' + props.token,
                      'Content-Type': 'application/json' 
                    }
                 })
                .then (response => response.json())
                .then (json => {
                    traer_importes_act(json)
                })
             }
        }
        function traer_importes_act(l_socios) {
            if(props.token!=='') {
                fetch(props.back + 'admin/traer_importes', {
                    method: 'GET',
                    headers: {
                      'Authorization': 'Bearer ' + props.token,
                      'Content-Type': 'application/json' 
                    }
                 })
                .then (response => response.json())
                .then (json => {
                    traer_importes(l_socios,json)
                })
             }
        }
        function traer_importes(l_socios,valor_act) {
            if(props.token==='') return
            fetch(props.back + 'admin/traer_importes_cuentas', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + props.token,
                    'Content-Type': 'application/json' 
                }
            })
            .then (response => response.json())
            .then (json => {
                traer_cuotas(l_socios,valor_act,json)
            })
        }
        traer_socios()
        setElegida(props.actividades.filter(a => a.grupo===props.act)[0].nombre)
    },[])

    useEffect(() => {
        setActiMul(props.actividades.filter(a => a.grupo===props.act))
        setdata(elegir())
    }, [elegida])
    
    useEffect (() => {
        setdata(elegir())
        setSeleccionados(cont)
    }, [socios])

    function traer_cuotas(l_socios,valores_act, valores) {
        fetch(props.back + 'admin/traer_cuotas', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())
        .then (json =>  {
            const hoy = new Date()
            const cuota_act = (hoy.getMonth() + 1).toString().padStart(2,'0') + ' - ' + hoy.getFullYear().toString()
            const id_cuota_act_s = json.filter(e => e.descrip === cuota_act && e.tipo==='socio')[0].id
            const id_cuota_act_d = json.filter(e => e.descrip === cuota_act && e.tipo==='actividad')[0].id
            const valor_d = valores_act.filter(e => props.act===e.grupo)[0]._avg.valor
            const data = l_socios.map(a => {
                const cant_cuotas_s = json.filter(e => e.tipo==='socio' && e.id < id_cuota_act_s && e.id >= a.ultima_s).length
                const cant_cuotas_d = json.filter(e => e.tipo==='actividad' && e.id < id_cuota_act_d && e.id >= a.ultima_d).length
                const valor_s = valores.filter(e => cate(a)===e.cate)[0].valor
                const nuevo = {...a, categoria: cate(a), importe: a.saldo_ant + cant_cuotas_s * (valor_s - (valor_s*a.bonifi_s / 100.0))  + cant_cuotas_d * (valor_d - (valor_d*a.bonifi_a / 100.0))}
                return nuevo
            })
            setSocios(data)
        })
    }

    function cate (socio) {
        const hoy = dayjs(new Date())
        const nac = dayjs(socio.nacimiento)
        const ed = hoy.diff(nac, 'year')
            
        const alta_soc = dayjs(socio.alta_sistema)
        const ant = hoy.diff(alta_soc, 'year')
        var cual ='Menor'
        if (ed>=5 && ed<18) cual= 'Cadete'
        if (ed>=18 && ed<60 && socio.sexo==='F') cual= 'Dama'
        if (ed>=18 && ed<65 && socio.sexo==='M') cual= 'Activo'
        if (ed>=48 && ant>=30) cual= 'Vitalicio'
        if ((ed>=60 && ant<30 && socio.sexo==='F') || (ed>=65 && ant<30 && socio.sexo==='M')) cual='Jubilado'
        return cual
    }
   
    function activi(id) {
        const t = socios.filter(a => a.id === id)
        if (t[0].actividad.length) {
            const zzz = t[0].actividad.map(a => {
                const act = props.actividades.filter(e => e.id ===a.actividadId)
                return {nombre: act[0].nombre, grupo: act[0].grupo}
            })
            return  zzz
        } else
            return [{nombre: 'Sin Actividad', grupo: 'Sin grupo'}]
    }

   const opciones = () => {
        const t = actiMul.map(a => {
            return (
            <option value={a.nombre}>{a.nombre}</option> 
            )
        })
        return t
    }


    function elegir () {
        if(!socios.length) return
        cont=0
        var r=[]
        var van = 0
        const verdadera = actiMul.length >1 ? elegida : props.act
        const result = socios.map(a =>  {
            const t = activi(a.id)
                const w = t.map(e => {
                    if (e.nombre===verdadera) {
                        const nacido = a.nacimiento.substring(0,4)
                        r.push({apellido: a.apellido, nombre: a.nombre, nacido: nacido, documento: a.documento })
                        cont++
                        return (<>
                            <div className="grid grid-cols-7 w-full" key={a.id}>
                            <label>{a.apellido}</label>
                            <label>{a.nombre}</label>
                            <label>{nacido}</label>
                            <label>{a.documento}</label>
                            <label>{a.telefono}</label>
                            <label className="text-right">{a.importe}</label>
                            </div>
                            </>)
                    }
                    })
                    return w
            })
        setResultado(r)
        setSeleccionados(cont)
        return result
    }

    return (<>
        <div className="px-2 flex flex-col w-full h-3/4 items-center">
            {actiMul !==undefined && actiMul.length >1 ?
            <div className="flex flex-row w-1/4 justify-between my-2">
                <label className="text-lg">
                    Categoria
                </label>
               
                <select
                    className="outline-0 px-2 text-lg "
                    value={elegida}
                    onChange={e => setElegida(e.target.value)}
                >
                    {opciones()}
                </select>
            </div>
                   : ''}
            <div className="grid grid-cols-7 my-2 w-full">
                <label>Apellido</label>
                <label>Nombre</label>
                <label>Nacido</label>
                <label>Documento</label>
                <label>Teléfono</label>
                <label className="text-center">Deuda Actual</label>
            </div>
            <div className="px-2 h-full overflow-y-auto mt-3 border-b-2 border-gray-600 w-full">
            {data}
           
           </div>   
           
        </div>
        <div className="w-full text-lg mt-2 text-center w-1/3 flex flex-row items-center justify-evenly">
        <div className="flex flex-row w-1/6 justify-between">
            <label>Seleccionados: </label>
            <label>{seleccionados}</label>
        </div>
        <button 
            className="text-lg text-white bg-green-600 p-3 rounded-xl"
            onClick={() => guardar()}
        >Descargar</button>
        </div>
    </>)
}