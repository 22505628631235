export default function BotonLogin (props) {
    const tam = window.innerWidth > 640
    const formato_md = "bg-gray-200 md:text-lg ml-5 p-2 rounded-lg border-2 border-gray-400 hover:text-white hover:bg-gray-600 w-30"
    const formato_sm = "bg-gray-200 text-sm ml-5 p-2 rounded-lg border-2 border-gray-400 hover:text-white hover:bg-gray-600"
    return (<>
    <button 
        className={tam? formato_md : formato_sm}
        onClick={(e) => props.setIsOpen(true)}
    >
    Ingreso Admin
    </button>
    </>)
}