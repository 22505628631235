import { useState } from 'react'
import SociosDatos from './socios_datos.js'
import Actividades from './actividades.js'
import Responsable from './responsable.js'
import BuscarSocio from '../buscar_socio.js'
import ControlesSocio from './controles_socio.js'
import Bonificaciones from './bonificaciones.js'
import Grupofamiliar from './grupo_familiar.js'
import Buscarsingrupo from './buscar_socio_sin_grupo.js'

export default function Socios(props) {
    const [file, setFile] = useState(null)
    const [editarG, setEditarG ] = useState(false)
    const [datos_socio, setDatos_socio] = useState({id: 0})
    
    return (<>
    <div className="w-full overflow-y-auto">
        {datos_socio.id ? (<>
        <div className="flex flex-col w-full md:flex-row h-[33rem] overflow-y-auto">
            <div className="w-full md:w-3/5 my-2 md:border-r-2 md:border-gray-600 overflow-y-auto"> 
            {!editarG ?
                <SociosDatos back={props.back} token={props.token} socio={datos_socio} setSocio={setDatos_socio}  file={file} setFile={setFile}/>
            :
                <Buscarsingrupo back={props.back} token={props.token} socio={datos_socio} setSocio={setDatos_socio} setEditarG={setEditarG} rec_socs={props.rec_socs} setRec_socs={props.setRec_socs} socios={props.socios}/>
            }
            </div>
            <div className="flex flex-col w-full md:w-2/5 overflow-y-auto">
                <div className="my-2 border-b-2 border-gray-600">
                    <Actividades socio={datos_socio} setSocio={setDatos_socio} actividades={props.actividades} />
                </div>
                <div className="my-2 border-b-2 border-gray-600">
                    <Bonificaciones back={props.back} socio={datos_socio} setSocio={setDatos_socio} token={props.token}/>
                </div>
                <div className="my-2 border-b-2 border-gray-600">
                    <Responsable back={props.back} token={props.token} socio={datos_socio} setSocio={setDatos_socio}/>
                </div>
                <div className="my-2 border-b-2 border-gray-600">
                    <Grupofamiliar back={props.back} socio={datos_socio} setSocio={setDatos_socio} token={props.token} setEditarG={setEditarG} rec_socs={props.rec_socs} setRec_socs={props.setRec_socs} socios={props.socios}/>
                </div>
            </div>
        </div>
        {datos_socio.id ?
            <ControlesSocio datos_u={props.datos_u} token={props.token} back={props.back} socio={datos_socio} setSocio={setDatos_socio}  file={file} setFile={setFile}/>
        : '' }
        </>)
        : 
        <div className="p-2">
            <BuscarSocio token={props.token} back={props.back} socios={props.socios} setDatos_socio={setDatos_socio} />
        </div>
        }
    </div>
        
    </>)
}