import { useState } from 'react'
import Socios from './Socio/socios.js'
import Ingreso from './Ingreso/ingreso.js'
import Importes from './Importes/importes.js'
import Cobranzas from './Cobranzas/cobranza.js'
import Listados from './Listados/listados.js'
import IconoMenu from '@mui/icons-material/Menu'

export default function Menu(props) {
    const [elegido, setElegido] = useState(0)
    const [movil, setMovil] = useState(false)

    const opciones = [
        {nombre: 'Socios', cual: 1}, 
        {nombre: 'Ingreso', cual: 2}, 
        {nombre: 'Importes', cual: 3}, 
        {nombre: 'Cobranzas', cual: 4}, 
        {nombre: 'Listados', cual: 6}, 
        {nombre: 'Alta', cual: 7}
    ]

    function seleccionar (cual) {
        setElegido(cual)
        const width = window.innerWidth;
        if (width<=640)
            setMovil(true)
        if (cual===7)
            window.open("https://clubrionegro.ar/alta")
    }
    
    return (<>
    <div className="md:hidden">
        <IconoMenu onClick={e => setMovil(!movil)}/>      
    </div>
    <div className="sm:hidden md:flex flex-row h-full">
    {!movil && (
        <div className="bg-zinc-500 sm:text-sm md:text-lg h-full ">
            <div className="flex flex-col p-2 justify-between">
                   {opciones.map (a => {
                        if ((a.cual===1 || a.cual===2 || a.cual===4 || a.cual===6) || props.datos_u.rol===3) {
                            if (a.cual!==elegido) {
                                return (<>
                                    <button 
                                        className="sm:text-sm md:text-lg bg-gray-200 hover:bg-gray-800 hover:text-white rounded-xl p-2 my-2"
                                        onClick={() => seleccionar(a.cual)}
                                        key={a.cual}
                                    >
                                    {a.nombre}
                                    </button>
                                </>)
                            } else {
                                return (<>
                                     <button 
                                        className="bg-gray-800 text-white rounded-xl p-2 my-2"
                                        onClick={() => seleccionar(a.cual)}
                                        key={a.cual}
                                    >
                                    {a.nombre}
                                    </button>
                                </>)
                            }
                        }
                   })
                }
                </div> 
            </div>
             )}
            <div className="w-11/12">
                {elegido===1 ? <Socios back={props.back} token={props.token} actividades={props.actividades} setRec_socs={props.setRec_socs} rec_socs={props.rec_socs} socios={props.socios} datos_u={props.datos_u} /> : ''}
                {elegido===2 ? <Ingreso back={props.back} token={props.token} actividades={props.actividades} socios={props.socios} /> : ''}
                {elegido===3 ? <Importes back={props.back} token={props.token} actividades={props.actividades} setElegido={setElegido} /> : ''}
                {elegido===4 ? <Cobranzas back={props.back} token={props.token} actividades={props.actividades} socio={props.socio} setSocio={props.setSocio} socios={props.socios} recargar={props.recargar} setRecargar={props.setRecargar} importes={props.importes} imp_social={props.imp_social} /> : ''}
                {elegido===5 ? <div className="flex flex-row items-center justify-center">Disciplinas</div> : ''}
                {elegido===6 ? <Listados back={props.back} token={props.token} actividades={props.actividades}  importes={props.importes} imp_social={props.imp_social} /> : ''}
            </div>
        </div>
    </>)
}