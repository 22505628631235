import BuscarSocio from '../buscar_socio.js'
import MostrarIngreso from './mostrar_ingreso.js'
import {useState, useEffect} from 'react'

export default function Ingreso(props) {
    const [datos_socio, setDatos_socio] = useState({id: 0})
    const [tipo, setTipo] = useState('socio')
    const [dni, setDni] = useState('')
    const [concepto, setConcepto] = useState([])
    const [elegido, setElegido] = useState('Pileta')
    const [una_vez, setUna_vez] = useState(false)
    const formato1 = "text-center text-lg"
    const formato2 = "text-center text-lg text-green-600"

    useEffect(() => {
        fetch(props.back + 'admin/traer_importes_nosocio',{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())
        .then (json => setConcepto(json))
    }, [])

    function validar (e) {
        if (/^\d+$/.test(e) && e.length<=8 || e==='') {
            setDni(e)
        }
    }

    function cobrar() {
        if  (dni==='') {alert('Documento Requerido'); return}
        setUna_vez(true)
        const t = concepto.filter(a => a.motivo === elegido)
        var data = {
            documento: dni,
            importe: t[0].valor,
            motivo: elegido
        }
        fetch(props.back + 'admin/recibo_guardar_ing', {
            body : JSON.stringify(data),
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())
        .then (json => {
            setDni('')
            setUna_vez(false)
        })
    }

    return (<>
    <div className="flex flex-col items-center justify-center  py-2 border-b-2 border-gray-600">
        <div className="flex flex-row justify-between w-1/3 ">
            <label className={tipo==='socio' ? formato2 : formato1}
                onClick={e => setTipo('socio')}
            >Socios</label>
            <label className={tipo==='Nosocio' ? formato2 : formato1}
                onClick={e => setTipo('nosocio')}
            >No Socios</label>
        </div>
    </div>
    {tipo==='socio' ? (<>
    <div className="w-full">
        <div className="flex flex-row">
            <div className="border-r-2 border-r-2 border-gray-600 px-3 w-1/2">
                <p className="text-center text-lg">Socio</p>
                <BuscarSocio back={props.back} token={props.token} actividades={props.actividades} socios={props.socios} setDatos_socio={setDatos_socio} h={'h-48'}/>
            </div>
            <div className="px-3 w-1/2">
                <p className="text-center text-lg">Autorizado</p>
                <BuscarSocio  back={props.back} token={props.token} actividades={props.actividades} socios={props.socios} setDatos_socio={setDatos_socio} h={'h-48'} cual={'ref'}/>
            </div>
        </div>
    </div>
    <div className="w-full px-3"> 
        {datos_socio.id ?
            <MostrarIngreso back={props.back} token={props.token} socio={datos_socio} actividades={props.actividades} />
        : ''
        }
    </div>
    </>)
    : 
        <div className="w-full flex flex-col items-center">
            <div className="flex flex-row w-1/3 justify-between py-2 mb-5">
                <label className="text-lg">Documento</label>
                <input 
                    type="text"
                    value={dni}
                    onChange={e => validar(e.target.value)}
                    className="px-2 ml-5"
                />
            </div>
            {concepto.map(a => {
                return (
                    <div className="mt-3 w-1/3 flex flex-row justify-between">
                        <input type='radio' value={a.motivo} onChange={e => setElegido(e.target.value)} checked={elegido===a.motivo} />
                        <div className="flex flex-row justify-between w-5/6">
                        <label className="text-lg">{a.motivo}</label>
                        <label className="text-lg">{a.valor}</label>
                        </div>
                    </div>
                )
                })}
            <button className="w-1/12 mt-5 text-center rounded-xl p-2 bg-green-500 hover:bg-green-800 hover:text-white" onClick={e => cobrar()} disabled={una_vez}>COBRAR</button>
            </div>

    }
    </>)
}